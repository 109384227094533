<template lang="pug">
  .fee-options
    span.title(v-if="item.fee_type.$model !== fixedFee") {{ $t('plan_settings.basic_settings.fee') }}
    AppNumberInput.long(
      type="number"
      v-model="item.fee.$model"
      :invalid="item.fee.$error"
      :max="Number.POSITIVE_INFINITY"
    )
    span.yen {{ $t("plan_settings.basic_settings.currency") }}
</template>

<script>
  import { FIXED_FEE } from "@/config/constants"

  export default {
    components: {
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    props: {
      item: {
        type: Object,
        default: () => ({})
      }
    },

    data() {
      return {
        fixedFee: FIXED_FEE
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .fee-options
    +fees-container

    .title
      padding-left: 8px
</style>
